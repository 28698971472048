import { useRef, useCallback, } from "react";
/**
 * useObserver
 * @param callback IntersectionObserverCallback
 * @param options IntersectionObserverInit
 * @param externalState React.ComponentState[]
 */
const useObserver = (callback, { root, rootMargin, threshold } = {}, externalState = []) => {
    const target = useRef(null);
    const observer = useRef(null);
    const setTarget = useCallback((node) => {
        if (target.current && observer.current) {
            observer.current.unobserve(target.current);
            observer.current.disconnect();
            observer.current = null;
        }
        if (node) {
            observer.current = new IntersectionObserver(callback, { root, rootMargin, threshold });
            observer.current.observe(node);
            target.current = node;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [target, root, rootMargin, JSON.stringify(threshold), ...externalState]);
    return setTarget;
};
export default useObserver;
